<template>
  <div class="logs">
    <h2>Отчет add-ins Outlook</h2>
    <ReportLogs :items="items" />
    <footer>
      <button @click.prevent="onClick">
        <Download />
        <span>Отчет в Excel</span>
      </button>
    </footer>
  </div>
</template>

<script>
import Download from '@/pic/svg/download.vue'
import ReportLogs from './comp/report-logs/report-logs.vue'
import { getDate, getTime } from './func'
import XLSX from 'xlsx'
export default {
  name: 'index-zoom-page',
  components: { ReportLogs, Download },
  props: {
    items: { type: Array }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    onClick() {
      const _name = `лог ${getDate(Date.now())}.xlsx`

      const bodyUser = this.items.map((e) => [e.name, e.email, `${getDate(e.ctime * 1000)} ${getTime(e.ctime * 1000)}`])

      const logTab = {
        name: 'пользователи',
        header: ['имя','почта','дата'],
        body: bodyUser
      }

      const arr = [logTab]
      const book = XLSX.utils.book_new()
      arr.forEach((item) => {
        const data = item.body
        data.unshift(item.header)
        const sheet = XLSX.utils.aoa_to_sheet(data)
        XLSX.utils.book_append_sheet(book, sheet, item.name)
      })
      XLSX.writeFile(book, _name)
    }
  },
  async created() {}
}
</script>

<style lang="scss">
@import './styles';
</style>
