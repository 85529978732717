<template>
  <Logs :items="logs" />
</template>

<script>
import Logs from './logs'
import { getAxiosUsersLog } from '@/views/logs/func'
export default {
  name: 'index-logs',
  components: { Logs },
  data() {
    return {
      logs: []
    }
  },
  async created() {
    this.logs = await getAxiosUsersLog()
  }
}
</script>
