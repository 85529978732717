<template>
  <table class="table-logs">
    <thead>
      <tr>
        <th v-for="(e, i) in reportHeader" :key="i">{{ e }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="n in items" :key="n.id">
        <td>{{ n.name }}</td>
        <td>{{ n.email }}</td>
        <td>{{ getTime(n.ctime) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { getDate, getTime } from '../../func'
export default {
  name: 'report-logs',
  components: {},
  props: {
    items: { type: Array }
  },
  data() {
    return {
      reportHeader: ['имя', 'почта', 'дата']
    }
  },
  methods: {
    getTime(time) {
      const _time = time * 1000
      return `${getDate(_time)} ${getTime(_time)}`
    },
  }
}
</script>

<style lang="scss">
@import './styles';
</style>
